import { HeadCell } from '../types';
import { Model, Product } from '../types/customer';

export const data = [
  {
    id: 1,
    filename: 'Payday_loan_1001_1201.csv',
    shared_user: ['Anna Yesenina', 'Federica Johnson'],
    user: 'Sam Smith',
    size: 5000,
    ceration_date: '12.07.2018'
  },
  {
    id: 2,
    filename: 'New_project_milestone.csv',
    shared_user: ['Sam Smith', 'Ashot Harutyunyan', 'Anush Petrosyan'],
    user: 'Federica Johnson',
    size: 15000,
    ceration_date: '12.07.2018'
  },
  {
    id: 3,
    filename: 'Distribution_changes.csv',
    shared_user: ['Franz Kafka', 'David Grigoryev'],
    user: 'Anush Petrosyan',
    size: 51000,
    ceration_date: '12.07.2018'
  },
  {
    id: 4,
    filename: 'Revenue_assessment.csv',
    shared_user: ['Sam Smith', 'Anush Petrosyan'],
    user: 'Ashot Harutyunyan',
    size: 3000,
    ceration_date: '12.07.2018'
  },
  {
    id: 5,
    filename: 'Sold_price_analysis.csv',
    shared_user: ['David Grigoryev', 'Anna Yesenina'],
    user: 'Albert Camus',
    size: 1244000,
    ceration_date: '12.07.2018'
  },
  {
    id: 6,
    filename: 'Payday_loan_1001_120122.csv',
    shared_user: ['Franz Kafka', 'David Grigoryev', 'Anna Yesenina', 'Suren Yeritspokhyan'],
    user: 'Suren Yeritspokhyan',
    size: 61000,
    ceration_date: '12.07.2018'
  },
  {
    id: 7,
    filename: 'Sold_analysis.csv',
    shared_user: ['Federica Johnson', 'Ashot Harutyunyan', 'Albert Camus', 'Anush Petrosyan'],
    user: 'David Grigoryev',
    size: 5700,
    ceration_date: '12.07.2018'
  },
  {
    id: 8,
    filename: 'Distribution.csv',
    shared_user: ['Sam Smith', 'Suren Yeritspokhyan'],
    user: 'Franz Kafka',
    size: 8000,
    ceration_date: '12.07.2018'
  },
  {
    id: 9,
    filename: 'assessment.csv',
    shared_user: ['Suren Yeritspokhyan', 'David Grigoryev'],
    user: 'Albert Camus',
    size: 91000,
    ceration_date: '12.07.2018'
  },
  {
    id: 10,
    filename: 'Payday.csv',
    shared_user: ['Sam Smith', 'Anush Petrosyan'],
    user: 'Suren Yeritspokhyan',
    size: 1000,
    ceration_date: '12.07.2018'
  },
  {
    id: 11,
    filename: 'New_project_mil.csv',
    shared_user: ['Anush Petrosyan', 'Ashot Harutyunyan', 'David Grigoryev', 'Armine Panosyan'],
    user: 'Sam Smith',
    size: 15000,
    ceration_date: '12.07.2018'
  },
  {
    id: 12,
    filename: 'Rev.csv',
    shared_user: ['Anush Petrosyan', 'Sam Smith'],
    user: 'David Grigoryev',
    size: 2000,
    ceration_date: '12.07.2018'
  },
  {
    id: 13,
    filename: 'Distribute.csv',
    shared_user: ['Ashot Harutyunyan', 'Sam Smith', 'David Grigoryev', 'Suren Yeritspokhyan'],
    user: 'Suren Yeritspokhyan',
    size: 35000,
    ceration_date: '12.07.2018'
  }
];

export const headCells: HeadCell<Product>[] = [
  {
    id: 'file_name',
    numeric: false,
    label: 'Dataset Name'
  },
  {
    id: 'user',
    numeric: false,
    label: 'Owner'
  },
  // {
  //   id: 'shared_user',
  //   numeric: false,
  //   label: 'Who Can See'
  // },
  {
    id: 'size',
    numeric: true,
    label: 'Size'
  },
  {
    id: 'creation_time',
    numeric: true,
    label: 'Upload Time'
  }
];

export const DatasourceDashboardSearch: string[] = ['file_name'];

export const ModelManagerDashboardSearch: string[] = ['file_name'];

export const ModelManagerDashboardHeader: HeadCell<Model>[] = [
  {
    id: 'file_name',
    numeric: false,
    label: 'Model Name'
  },
  {
    id: 'ds_name',
    numeric: false,
    label: 'Dataset Name'
  },
  // {
  //   id: 'tags',
  //   numeric: false,
  //   label: 'Tags'
  // },
  {
    id: 'model_type',
    numeric: false,
    label: 'Model Type '
  },
  {
    id: 'task',
    numeric: false,
    label: 'Problem Type'
  },
  {
    id: 'model_status',
    numeric: false,
    label: 'Status'
  },
  {
    id: 'user',
    numeric: false,
    label: 'Owner'
  },
  {
    id: 'creation_time',
    numeric: false,
    label: 'Creation Date'
  },
  {
    id: 'size',
    numeric: true,
    label: 'Size'
  }
];

// export const ModelManagerData = [
//   {
//     id: 1,
//     filename: 'XGBoost_on_loan_data.pkl',
//     datasourceName: 'Payday_loan_1001_120111.csv',
//     tags: 156,
//     trainedModels: [{ label: 'NN', color: 'warning' }],
//     type: 'Regression',
//     status: 'Deployed',
//     owner: 'name_1',
//     creationDate: '12/09/2022',
//     size: 5000,
//     model: 'XGBoost_on_loan_data',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0, 0.15, 0.35, 0.55, 0.65, 0.625, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 2,
//     filename: 'LGBM_Revenue.pkl',
//     datasourceName: 'Revenue_assessment4.csv',
//     tags: 145,
//     trainedModels: [{ label: 'C3', color: 'success' }],
//     type: 'Regression',
//     status: 'Saved',
//     owner: 'name_2',
//     creationDate: '12/09/2022',
//     size: 50200,
//     model: 'LGBM_Revenue',
//     recall: '42%',
//     precision: '34%',
//     f1_score: '55%',
//     accuracy: '85%',
//     auc: '81%',
//     chart: [0, 0.25, 0.32, 0.41, 0.52, 0.63, 0.71, 0.85, 0.94, 0.99]
//   },
//   {
//     id: 3,
//     filename: 'NN_best_model.pkl',
//     datasourceName: 'Sold_price_analysis4.csv',
//     tags: 132,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_3',
//     creationDate: '12/09/2022',
//     size: 35000,
//     model: 'NN_best_model',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.3, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 4,
//     filename: 'Logistic_Regression.pkl',
//     datasourceName: 'New_project_milestone.csv',
//     tags: 124,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_4',
//     creationDate: '12/09/2022',
//     size: 45000,
//     model: 'Logistic_Regression',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.4, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 5,
//     filename: 'NN_distribution.pkl',
//     datasourceName: 'Distribution_changes3.csv',
//     tags: 165,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_5',
//     creationDate: '12/09/2022',
//     size: 55000,
//     model: 'NN_distribution',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.5, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 6,
//     filename: 'XG.pkl',
//     datasourceName: 'Payday_load_1221_1201.csv',
//     tags: 147,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_6',
//     creationDate: '12/09/2022',
//     size: 600,
//     model: 'XG',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.6, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 7,
//     filename: 'Revenue.pkl',
//     datasourceName: 'Revenue_assessment.csv',
//     tags: 176,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Regression',
//     status: 'Deployed',
//     owner: 'name_7',
//     creationDate: '12/09/2022',
//     size: 37000,
//     model: 'Revenue',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.7, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 8,
//     filename: 'Best_model.pkl',
//     datasourceName: 'Sold_price_analysis.csv',
//     tags: 188,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Regression',
//     status: 'Deployed',
//     owner: 'name_8',
//     creationDate: '12/09/2022',
//     size: 8000,
//     model: 'Best_model',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.8, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//   {
//     id: 9,
//     filename: 'Distribution.pkl',
//     datasourceName: 'Distribution_changes.csv',
//     tags: 209,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_9',
//     creationDate: '12/09/2022',
//     size: 900,
//     model: 'Distribution',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.9, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//
//   {
//     id: 10,
//     filename: 'XGBoost.pkl',
//     datasourceName: 'Changes.csv',
//     tags: 305,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Regression',
//     status: 'Deployed',
//     owner: 'name_10',
//     creationDate: '12/09/2022',
//     size: 10000,
//     model: 'XGBoost',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.93, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   },
//
//   {
//     id: 11,
//     filename: 'NN_best.pkl',
//     datasourceName: 'Sold_price_analysis.csv',
//     tags: 506,
//     trainedModels: [{ label: 'Ex', color: 'secondary' }],
//     type: 'Classification',
//     status: 'Deployed',
//     owner: 'name_11',
//     creationDate: '12/09/2022',
//     size: 35000,
//     model: 'NN_best',
//     recall: '36%',
//     precision: '65%',
//     f1_score: '48%',
//     accuracy: '87%',
//     auc: '71%',
//     chart: [0.95, 0.4, 0.3, 0.5, 0.6, 0.62, 0.69, 0.75, 0.88, 0.91, 0.99]
//   }
// ];

export const RowsPerPage: number[] = [10, 25];
type Icons = 'shape' | 'NA' | 'numeric' | 'object';

export const datasourceSummaryIcons: { icon: Icons; title: string; info: string; color: string }[] = [
  { icon: 'shape', title: 'Shape', info: '(5000, 10)', color: '#FCA311' },
  { icon: 'NA', title: 'NA values', info: '3916', color: '#EF233C' },
  { icon: 'numeric', title: 'Numeric features', info: '2', color: '#0EAD69' },
  { icon: 'object', title: 'Categorical columns', info: '7', color: '#3DA5D9' }
];
