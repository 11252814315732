import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import MainCard from '../MainCard';
import { IAnalyzeDashboardData, IDashboardProps } from '../../types';
import { dispatch } from '../../store/hooks';
import { userMiddleware } from '../../store/slices/user';
import { removeLastExtension } from '../../helpers/removeExtension';

// table columns
const columnsClassification = [
  { field: 'modelName', headerName: 'Model Name' },
  { field: 'model', headerName: 'Model' },
  { field: 'recall', headerName: 'Recall' },
  { field: 'precision', headerName: 'Precision' },
  { field: 'f1_score', headerName: 'F1-score' },
  { field: 'accuracy', headerName: 'Accuracy' },
  { field: 'auc', headerName: 'AUC' }
];

const columnsRegression = [
  { field: 'modelName', headerName: 'Model Name' },
  { field: 'model', headerName: 'Model' },
  // { field: 'AIC', headerName: 'AIC' },
  { field: 'Adjusted_R2', headerName: 'Adjusted_R2' },
  // { field: 'BIC', headerName: 'BIC' },
  { field: 'MAE', headerName: 'MAE' },
  { field: 'MSE', headerName: 'MSE' },
  { field: 'R2', headerName: 'R2' },
  { field: 'RMSE', headerName: 'RMSE' }
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary[800],
    color: theme.palette.common.white,
    textAlign: 'center'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'center'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-of-type td, &:last-of-type th': {
    border: 0
  }
}));

const AnalyzeManagerTable = (props: IAnalyzeDashboardData) => {
  const { analyzeManagerTableData } = props;

  const allAreClassification =
    analyzeManagerTableData &&
    analyzeManagerTableData?.length > 0 &&
    analyzeManagerTableData?.every((item) => item.Problem_type === 'classification');
  const allAreRegression =
    analyzeManagerTableData &&
    analyzeManagerTableData?.length > 0 &&
    analyzeManagerTableData?.every((item) => item.Problem_type === 'regression');
  const containsBoth =
    analyzeManagerTableData &&
    analyzeManagerTableData?.length > 0 &&
    analyzeManagerTableData?.some((item) => item.Problem_type === 'classification') &&
    analyzeManagerTableData?.some((item) => item.Problem_type === 'regression');

  return (
    <>
      {analyzeManagerTableData && analyzeManagerTableData.length > 0 && (
        <MainCard content={false} sx={{ width: '100%' }}>
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ width: '100%' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {analyzeManagerTableData && analyzeManagerTableData.length > 0 && (
                    <>
                      {allAreClassification && (
                        <>
                          {columnsClassification.map((headCell) => (
                            <StyledTableCell sx={{ pl: 3 }} key={headCell.field}>
                              {headCell.headerName}
                            </StyledTableCell>
                          ))}
                        </>
                      )}

                      {allAreRegression && (
                        <>
                          {columnsRegression.map((headCell) => (
                            <StyledTableCell sx={{ pl: 3 }} key={headCell.field}>
                              {headCell.headerName}
                            </StyledTableCell>
                          ))}
                        </>
                      )}

                      {containsBoth && <div style={{ display: 'none' }} />}
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {analyzeManagerTableData &&
                  analyzeManagerTableData.length > 0 &&
                  allAreClassification &&
                  analyzeManagerTableData?.map((row: any) => (
                    <StyledTableRow hover key={row?.filename}>
                      <StyledTableCell
                        title={removeLastExtension(row.filename)}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '40px' }}
                      >
                        {removeLastExtension(row?.filename)}
                      </StyledTableCell>
                      <StyledTableCell>{row?.model}</StyledTableCell>
                      <StyledTableCell>{(row?.recall).toFixed(2)}%</StyledTableCell>
                      <StyledTableCell>{(row?.precision).toFixed(2)}%</StyledTableCell>
                      <StyledTableCell>{(row?.f1_score).toFixed(2)}%</StyledTableCell>
                      <StyledTableCell>{(row?.accuracy).toFixed(2)}%</StyledTableCell>
                      <StyledTableCell>{row?.auc}%</StyledTableCell>
                    </StyledTableRow>
                  ))}

                {analyzeManagerTableData &&
                  analyzeManagerTableData.length > 0 &&
                  allAreRegression &&
                  analyzeManagerTableData?.map((row: any) => (
                    <StyledTableRow hover key={row?.filename}>
                      <StyledTableCell
                        title={row.filename}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '40px' }}
                      >
                        {row?.filename}
                      </StyledTableCell>
                      <StyledTableCell>{row?.model}</StyledTableCell>
                      {/*<StyledTableCell>{row?.AIC}</StyledTableCell>*/}
                      <StyledTableCell>{row?.Adjusted_R2}</StyledTableCell>
                      {/*<StyledTableCell>{row?.BIC}</StyledTableCell>*/}
                      <StyledTableCell>{row?.MAE}</StyledTableCell>
                      <StyledTableCell>{row?.MSE}</StyledTableCell>
                      <StyledTableCell>{row?.R2}</StyledTableCell>
                      <StyledTableCell>{row?.RMSE}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </MainCard>
      )}
    </>
  );
};

export default AnalyzeManagerTable;
