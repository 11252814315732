import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Toolbar, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { EnhancedTableToolbarProps, IDashboardAction } from '../../types';
import { dispatch, useAppSelector } from '../../store/hooks';
import { downloadMiddleware, downloadSelector } from '../../store/slices/download';
import store from '../../store/store';
import API from '../../manager/API';
import { toast } from 'react-toastify';
import {
  deleteDialogOpen,
  inferenceDialogOpen,
  modelViewDialogOpen,
  summaryDialogOpen,
  urlDialogOpen
} from './Actions/ModelActions';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName, SeveritiesType } from '../../types/modals';
import { deployMiddleware } from '../../store/slices/deploy';
import { deployStatusMiddleware } from '../../store/slices/deployStatus';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { undeployMiddleware } from '../../store/slices/undeploy';
import ModelSetupModalComponent from '../../modals/ModelSetupModal/ModelSetupModal.component';
import useOpen from '../../hooks/UseOpen';
import { removeLastExtension } from '../../helpers/removeExtension';

const DashboardToolbar = ({
  isModelSetupModalOpen,
  setIsModelSetupModalOpen,
  selected,
  setSelected,
  numSelected,
  actions,
  setActions,
  isModels,
  fileSize,
  rowData,
  setRowData,
  data,
  rowDataStatus,
  setRowDataStatus,
  setChecked
}: EnhancedTableToolbarProps) => {
  const theme = useTheme();
  const userId = localStorage.getItem('userId');
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const [currentRow, setCurrentRow] = useState<any>({});
  const [shapValueWanted, setShapValueWanted] = useState(false);
  const [showConfirmDeployTooltip, setShowConfirmDeployTooltip] = useState(false);
  const [action, setAction] = useState<IDashboardAction | null>(null);
  const [index, setIndex] = useState(0);
  const getUserModelsURL = `/datasource/user_models?container_name=containerfromblobservice${userId}`;
  const { open, setOpen } = useOpen();

  const uploadedModelStatus = rowData.model_status === 'Uploaded';

  const openModelSetupModal = () => {
    setIsModelSetupModalOpen(true);
  };

  const closeModelSetupModal = () => {
    setIsModelSetupModalOpen(false);
  };

  const previewDialogOpen = ({ fileName }: { fileName: string }, { fileSize }: { fileSize: number }) => {
    dispatch(modalsMiddleware.openModal({ name: ModalName.PreviewModal, props: { fileName, fileSize } }));
  };

  const toastNotification = () => {
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Hello, i am error'
        }
      })
    );
  };

  const [modelActionsSingle, setModelActionsSingle] = useState<any>([]);
  useEffect(() => {
    setCurrentRow(data.find((item: any) => item.file_name === selected[0]));
    setModelActionsSingle([
      {
        id: 'Setup',
        title: 'Setup',
        name: 'setup',
        style: {
          display: !uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#FCA311',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#F77F00'
          }
        },
        color: 'primary',
        state: true
      },
      // {
      //   id: 'View',
      //   title: 'View',
      //   name: 'view',
      //   style: {
      //     display: uploadedModelStatus ? 'none' : 'block',
      //     backgroundColor: '#FCA311',
      //     color: '#ffffff',
      //     '&:hover': {
      //       backgroundColor: '#F77F00'
      //     }
      //   },
      //   color: 'primary',
      //   state: true,
      //   openFunction: modelViewDialogOpen
      // },
      {
        id: 'Summary',
        title: 'Summary',
        name: 'summary',
        style: {
          display: uploadedModelStatus || rowData.model_type === 'uploaded_model' ? 'none' : 'block',
          backgroundColor: '#3DA5D9',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#2364AA'
          }
        },
        color: 'primary',
        state: true,
        openFunction: summaryDialogOpen
      },
      {
        id: 'Inference',
        title: 'Predict',
        name: 'inference',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#0EAD96',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#1A936F'
          }
        },
        color: 'error',
        state: true,
        openFunction: inferenceDialogOpen
      },
      {
        id: 'View_url',
        title: 'URL',
        name: 'View URL',
        style: {
          display: uploadedModelStatus || currentRow?.model_status === 'Saved' ? 'none' : 'block',
          color: '#ffffff',
          backgroundColor: '#FCA311',
          '&:hover': {
            backgroundColor: '#F77F00'
          }
        },
        color: 'secondary',
        state: true,
        openFunction: urlDialogOpen
      },
      {
        id: currentRow?.model_status === 'Saved' ? 'Deploy' : 'Undeploy',
        title: currentRow?.model_status === 'Saved' ? 'Deploy' : 'Undeploy',
        name: 'deploy',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          color: '#ffffff',
          backgroundColor: '#0EAD96',
          '&:hover': {
            backgroundColor: '#1A936F'
          }
        },
        color: 'secondary',
        state: true,
        openFunction: toastNotification,
        valueOnClick: {
          hideMe: true,
          field: rowData?.model_status === 'Saved' ? 'Undeploy' : 'Deploy'
        }
      },
      {
        id: 'Undeploy',
        title: 'Undeploy',
        name: 'undeploy',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#0EAD96',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#1A936F'
          }
        },
        color: 'warning',
        state: false,
        // openFunction: deployUndeployButton,
        valueOnClick: {
          hideMe: true,
          field: 'Deploy'
        }
      },
      {
        id: 'Drop',
        title: 'Drop',
        name: 'drop',
        style: {
          backgroundColor: '#EF233C',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#D90429'
          }
        },
        color: 'error',
        state: true,
        openFunction: deleteDialogOpen
      }
    ]);
  }, [data, currentRow, selected]);

  const pendingAction = async (isShap: boolean, action?: any, index: number = 0) => {
    const newActions = [...modelActionsSingle];

    if (action.valueOnClick.hideMe) {
      const newValue = newActions[modelActionsSingle.findIndex((item: any) => item.id === action.valueOnClick.field)];

      newActions[index].state = false;
      newValue.state = true;
      setIsLoading(true);
      setRowData({ ...rowData, isLoading: true });
      setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: true }));
    }
    // }
    const fileName = rowData.file_name;
    const params = {
      container_name: `containerfromblobservice${userId}`,
      model_name: fileName,
      is_shap: isShap
    };
    const deployUrl = '/model_deployment/model_deploy_setup';

    const deployData = await dispatch(deployMiddleware.getDeploy(deployUrl, params));

    if (deployData && deployData.status === 200) {
    } else {
      setIsLoading(false);
      setRowData({ ...rowData, isLoading: false });
      setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
    }

    if (deployData && Object.keys(deployData).length > 0 && deployData?.data?.status_update) {
      const deployStatusUrl = `/model_deployment/deploy_status/${deployData.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        try {
          const deployStatusData = await dispatch(deployStatusMiddleware.getDeployStatus(deployStatusUrl));
          if (deployStatusData && deployStatusData.status === 200) {
            if (deployStatusData && deployStatusData.data.queue_state === 'SUCCESS') {
              dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
              toast.success('Deploy successful!');
              // setA(true);
              setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
              if (setActions) {
                const newActions = [...modelActionsSingle];
                setActions(newActions);
                setIsLoading(false);
                setRowData({ ...rowData, isLoading: false });
              }
              clearInterval(intervalId);
            } else if (
              (deployStatusData && deployStatusData.data.queue_state === 'FAILURE') ||
              (deployStatusData && Object.keys(deployStatusData).length === 0)
            ) {
              // setA(false);
              setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
              if (setActions) {
                const newActions = [...modelActionsSingle];
                setActions(newActions);
                setIsLoading(false);
                setRowData({ ...rowData, isLoading: false });
              }
              clearInterval(intervalId);
              toast.error(removeLastExtension(deployStatusData.data.message));
            }
          } else {
            // setA(false);
            if (setActions) {
              const newActions = [...modelActionsSingle];
              setActions(newActions);
              setIsLoading(false);
              setRowData({ ...rowData, isLoading: false });
              setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
            }
            clearInterval(intervalId);
            toast.error(removeLastExtension(deployStatusData?.data.message));
          }
        } catch (error) {
          setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
          if (setActions) {
            const newActions = [...modelActionsSingle];
            setActions(newActions);
            setIsLoading(false);
            setRowData({ ...rowData, isLoading: false });
          }
          clearInterval(intervalId);
          toast.error('Error in status endpoint!');
        }
      }, 5000);
    }
  };

  const handleAccept = async () => {
    setShowConfirmDeployTooltip(false);
    await pendingAction(true, action, index);
  };
  const handleDecline = async () => {
    setShowConfirmDeployTooltip(false);
    await pendingAction(false, action, index);
  };
  const findIdByFilename = (filename: any) => {
    const file = data.find((item: any) => item.file_name === filename);
    return file ? file.id : null; // return the ID if the file is found, otherwise return null
  };

  return (
    <Toolbar
      sx={{
        p: 0,
        pr: 1,
        ...(numSelected > 0 && {
          color: theme.palette.secondary.main
        })
      }}
    >
      {numSelected > 0 && (
        <Typography color="inherit" variant="h4">
          {numSelected} Selected
        </Typography>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {numSelected > 0 && (
        <div style={{ display: 'flex', gap: '10px' }}>
          {!isModels &&
            actions.map((action) => (
              <Grid item id={action.title}>
                {action.name === 'download' && (
                  <Tooltip title="You need to allow multiply download from your browser settings" arrow>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        if (action.openFunction) {
                          for (const el of selected) {
                            const downloadFileUrl = `/blob_file/download?container_name=containerfromblobservice${userId}&file_name=${el}`;
                            try {
                              setIsDownloaded(true);
                              // await dispatch(downloadMiddleware.downloadFile(downloadFileUrl));
                              const a = await API.download.downloadFile(downloadFileUrl);
                              const fileContents = downloadSelector.downloadData(store.getState());
                              if (a.status === 200) {
                                setIsDownloaded(false);
                                toast.success(`Downloaded ${el}:`);
                              } else {
                                toast.error(removeLastExtension(a.data.message));
                              }

                              const blob = new Blob([a.data], { type: 'application/octet-stream' });
                              const url = window.URL.createObjectURL(blob);
                              const link = document.createElement('a');
                              link.href = url;
                              link.download = el;
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            } catch (error: any) {
                              setIsDownloaded(false);
                              toast.error(removeLastExtension(error?.response.data.message));
                              console.error(`An error occurred while downloading the file ${el}:`, error);
                            }
                          }
                        }
                      }}
                      sx={action.style}
                    >
                      {isDownloaded ? (
                        <CircularProgress style={{ height: '25px', width: '25px', color: 'blue' }} />
                      ) : (
                        action.title
                      )}
                    </Button>
                  </Tooltip>
                )}
                {action.name === 'delete' && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (setActions) {
                        setActions(action.valueOnClick);
                      }

                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected },
                          { fileSize: 0 },
                          { id: rowData.model_id ?? '' },
                          { setChecked },
                          { shapValueWanted },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}
                {action.name === 'summary' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected[0] },
                          { fileSize: fileSize ?? fileSize },
                          { id: rowData.model_id ?? '' },
                          { setChecked },
                          { shapValueWanted },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}

                {action.name === 'preview' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected[0] },
                          { fileSize: fileSize ?? fileSize },
                          { id: rowData.model_id ?? '' },
                          { setChecked },
                          { shapValueWanted },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}
              </Grid>
            ))}
          {isModels &&
            modelActionsSingle.map((action: any, index: number) => (
              <>
                {action.name === 'summary' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected[0] },
                          { fileSize: fileSize ?? fileSize },
                          { id: rowData.model_id ?? '' },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}

                {currentRow?.model_status === 'Deployed' && action.name === 'View URL' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: rowData.filename ?? rowData.file_name },
                          { fileSize: rowData.size ?? rowData.file_name },
                          { id: rowData.model_id ?? '' },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}

                {currentRow?.model_status === 'Saved' && action.name === 'deploy' && numSelected === 1 && (
                  <>
                    {selected.map((filename) => {
                      const id = findIdByFilename(filename);
                      return (
                        <Grid item id={filename}>
                          {/* Implement conditional rendering based on the id existence and its status in rowDataStatus */}
                          {rowDataStatus[id] ? (
                            <Box sx={{ p: 1 }}>
                              <CircularProgress style={{ height: '25px', width: '25px', color: 'blue' }} />
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={async () => {
                                if (rowData.model_type === 'uploaded_model') {
                                  await pendingAction(false, action, index);
                                } else {
                                  setShowConfirmDeployTooltip(true);
                                }
                                setAction(action);
                                setIndex(index);
                              }}
                              sx={action.style}
                            >
                              {action.title}
                            </Button>
                          )}
                        </Grid>
                      );
                    })}
                  </>
                )}

                {currentRow?.model_status === 'Deployed' && action.name === 'undeploy' && numSelected === 1 && (
                  <>
                    {selected.map((filename) => {
                      const id = findIdByFilename(filename);
                      return (
                        <Grid item id={filename}>
                          {/* Implement conditional rendering based on the id existence and its status in rowDataStatus */}
                          {rowDataStatus[id] ? (
                            <Box sx={{ p: 1 }}>
                              <CircularProgress style={{ height: '25px', width: '25px', color: 'blue' }} />
                            </Box>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={async () => {
                                const newActions = [...modelActionsSingle];
                                setIsLoading(true);
                                setRowData({ ...rowData, isLoading: true });
                                setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: true }));

                                const fileName = rowData.file_name;
                                const unDeployUrl = `/model_deployment/undeploy_model?model_id=${fileName}&container_name=containerfromblobservice${userId}`;
                                const unDeployData = await dispatch(undeployMiddleware.getUndeploy(unDeployUrl));

                                if (unDeployData && unDeployData.status === 200) {
                                  if (setActions) {
                                    const newActions = [...modelActionsSingle];
                                    setActions(newActions);
                                    setIsLoading(false);
                                    setRowData({ ...rowData, isLoading: false });
                                    setRowDataStatus((prevStatus: any) => ({ ...prevStatus, [rowData.id]: false }));
                                  }
                                  dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
                                  toast.success('Undeploy successful!');
                                } else {
                                  toast.error(removeLastExtension(unDeployData?.data.message));
                                }
                              }}
                              sx={action.style}
                            >
                              {action.title}
                            </Button>
                          )}
                        </Grid>
                      );
                    })}
                  </>
                )}

                {action.name === 'setup' && numSelected === 1 && (
                  <Button variant="contained" onClick={openModelSetupModal} sx={action.style}>
                    {action.title}
                  </Button>
                )}

                {action.name === 'view' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected[0] },
                          { fileSize: fileSize ?? fileSize },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}

                {action.name === 'inference' && numSelected === 1 && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected[0] },
                          { fileSize: 0 },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}
                {action.name === 'drop' && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (action.openFunction) {
                        action.openFunction(
                          { fileName: selected },
                          { fileSize: 0 },
                          { id: currentRow.id },
                          { setChecked },
                          { shapValueWanted },
                          { isModels: isModels ? isModels : false },
                          { selected },
                          { setSelected }
                        );
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title}
                  </Button>
                )}
              </>
            ))}
        </div>
      )}
      {showConfirmDeployTooltip && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '-93px', // Adjust the top position as needed
            right: '-6%',
            transform: 'translateX(-50%)',
            backgroundColor: 'lightblue',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
          }}
          className="confirmationTooltip"
        >
          <p style={{ color: '#000' }}>Create service for Shap values?</p>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button onClick={handleAccept}>Yes</button>
            <button onClick={handleDecline}>No</button>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderTop: '10px solid lightblue',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent'
            }}
          />
        </div>
      )}
      {isModelSetupModalOpen && (
        <ModelSetupModalComponent
          setOpen={setOpen}
          modelName={rowData.file_name}
          onClose={closeModelSetupModal}
          title="Model Setup"
        />
      )}
    </Toolbar>
  );
};

export default DashboardToolbar;
