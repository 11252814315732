import React, { useEffect, useMemo, useState } from 'react';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { getComparator, stableSort } from 'helpers/tableSort';
import { DashboardBodyProps, HeadCell } from 'types';

import { MbConverter } from '../../helpers/MbConverter';
import { useAppSelector } from '../../store/hooks';
import { modelsSelector } from '../../store/slices/models';
import { Header, Status } from '../../types/components/dashboard';
import { Model, Product } from '../../types/customer';
import Chip from '../Form/Chip';

import DashboardActions from './DashboardActions';
import { convertDatetime } from '../../helpers/dateTimeConverter';
import CloseIcon from '@mui/icons-material/Close';
import { downloadSelector } from '../../store/slices/download';
import { authSelector } from '../../store/slices/auth';
import { removeLastExtension } from '../../helpers/removeExtension';

// eslint-disable-next-line max-lines-per-function
const DashboardBody = ({
  checked,
  setChecked,
  isModelSetupModalOpen,
  setIsModelSetupModalOpen,
  a,
  setA,
  isModels,
  rows,
  order,
  orderBy,
  handleClick,
  selected,
  setSelected,
  page,
  rowsPerPage,
  actions,
  tableHeaders,
  setActions
}: DashboardBodyProps) => {
  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const loadingRowName = useAppSelector(modelsSelector.loadingModel);
  const authData = useAppSelector(authSelector.authData);

  const [openActions, setOpenActions] = useState<number>(-1);
  const [value, setValue] = useState('');
  const [choices, setChoices] = useState<any[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [shapValueWanted, setShapValueWanted] = useState(false);
  const [newChoice, setNewChoice] = useState('');
  const [showConfirmDeployTooltip, setShowConfirmDeployTooltip] = useState(false);

  const { downloadData } = useAppSelector(downloadSelector.downloadData);
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const deselectAllRows = () => {
    setSelected([]);
    return false;
  };

  const handleNewChoice = (e: any) => {
    setNewChoice(e.target.value);
  };

  const saveChoice = () => {
    setChoices([...choices, newChoice]);
    setValue(newChoice);
    setNewChoice('');
    closeModal();
  };

  useEffect(() => {
    if (loadingRowName) {
      setOpenActions(-1);
    }
  }, [loadingRowName]);

  const actionRowSx = useMemo(
    () => ({
      '& td:last-of-type>div': {
        position: 'absolute',
        top: '50%',
        right: 5,
        transform: 'translateY(-50%)',
        display: 'none',
        background: '#fff',
        borderRadius: '12px',
        p: 1.75
      }
    }),
    []
  );

  return (
    <>
      <TableBody>
        {stableSort(rows, getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          // eslint-disable-next-line max-lines-per-function
          .map((row: any, index) => {
            if (typeof row === 'number') {
              return null;
            }
            // setChecked(isSelected(row[tableHeaders[0].id]));

            const isItemSelected = isSelected(row[tableHeaders[0].id]);
            // setChecked(isItemSelected);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                style={{
                  position: 'relative',
                  opacity: row.disabled ? '0.5' : '2',
                  pointerEvents: row.hasOwnProperty('disabled') ? 'none' : 'auto',
                  backgroundColor: row.hasOwnProperty('disabled')
                    ? 'lightblue'
                    : isItemSelected
                    ? 'lightblue'
                    : 'inherit',
                  animation: row.hasOwnProperty('disabled') ? 'pulsate 2s ease-in-out infinite' : 'none'
                }}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row[tableHeaders[0].id]}
                selected={isItemSelected}
                sx={actionRowSx}
              >
                <style>{`
            @keyframes pulsate {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0;
    }
    75% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
        `}</style>
                <TableCell
                  padding="checkbox"
                  sx={{ pl: 3 }}
                  onClick={(event) => {
                    handleClick(event, row, row[tableHeaders[0].id]);
                    setOpenActions(-1);
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId
                    }}
                  />
                </TableCell>
                {tableHeaders.map((header: HeadCell<Product & Model>, headerIndex) => {
                  if (headerIndex === 0) {
                    return (
                      <TableCell
                        key={header.id}
                        title={row.file_name ? removeLastExtension(row.file_name) : ''}
                        component="th"
                        id={labelId}
                        scope="row"
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                        sx={{ cursor: 'pointer' }}
                      >
                        <Typography
                          style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '210px' }}
                          variant="subtitle1"
                          sx={{
                            color: 'grey.900'
                          }}
                        >
                          {row[header.id] ? removeLastExtension(row[header.id]) : ''}
                        </Typography>
                      </TableCell>
                    );
                  }

                  if (header.id === Header.status) {
                    return row.model_status === Status.Deployed ? (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        <Chip
                          label={row.model_status}
                          style={{ fontSize: '0.875rem' }}
                          size="small"
                          chipcolor="success"
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        <Chip
                          label={row.model_status}
                          style={{ fontSize: '0.875rem' }}
                          size="small"
                          chipcolor="warning"
                        />
                      </TableCell>
                    );
                  }

                  if (header.id === Header.size) {
                    const sizeByMb = MbConverter(row.size);

                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {typeof row.size === 'number' ? sizeByMb : ''}
                      </TableCell>
                    );
                  }

                  if (header.id === Header.creation_time) {
                    const convertedDate = convertDatetime(row.creation_time);

                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {convertedDate !== 'Invalid Date' ? convertedDate : null}
                      </TableCell>
                    );
                  }
                  if (header.id === Header.user) {
                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {authData?.authData.user_info?.user_me_data?.email}
                      </TableCell>
                    );
                  }

                  if (header.id === Header.model_type) {
                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {row.model_type}
                      </TableCell>
                    );
                  }

                  if (header.id === Header.task) {
                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {row.task}
                      </TableCell>
                    );
                  }

                  if (header.id === Header.ds_name) {
                    return (
                      <TableCell
                        sx={{ cursor: 'pointer' }}
                        onClick={(event) => handleClick(event, row, row[tableHeaders[0].id])}
                      >
                        {removeLastExtension(row?.ds_name)}
                      </TableCell>
                    );
                  }

                  if (header.id === Header.tags) {
                    return (
                      <td style={{ position: 'relative' }}>
                        {choices.map((choice, index) => (
                          <div
                            key={index}
                            style={{
                              position: 'relative',
                              display: 'inline-block',
                              backgroundColor: '#fff',
                              padding: '10px',
                              marginRight: '20px',
                              borderRadius: '5px'
                            }}
                          >
                            <span>{choice}</span>
                            <span
                              onClick={() => {
                                let newArray = [...choices];
                                newArray.splice(index, 1);
                                setChoices(newArray);
                              }}
                              style={{
                                right: '0',
                                top: '0',
                                backgroundColor: 'grey',
                                color: '#fff',
                                cursor: 'pointer',
                                marginLeft: '10px',
                                borderRadius: '5px',
                                padding: '2px'
                              }}
                            >
                              x
                            </span>
                          </div>
                        ))}
                        <Button style={{ position: 'absolute', right: '0', top: '20px' }} onClick={() => openModal()}>
                          +
                        </Button>
                        <datalist id="choices">
                          {choices.map((choice, index) => (
                            <option key={index} value={choice} />
                          ))}
                        </datalist>
                      </td>
                    );
                  }

                  return <TableCell>{row[header.id]}</TableCell>;
                })}

                <TableCell align="center" sx={{ pr: 3, position: 'relative' }}>
                  {selected.length === 0 && (
                    <IconButton
                      size="large"
                      onClick={() => {
                        if (!isItemSelected) {
                          setOpenActions(row.id);
                          setShowConfirmDeployTooltip(false);
                        }
                      }}
                    >
                      {selected.length === 0 && <MoreHorizOutlinedIcon sx={{ fontSize: '1.3rem' }} />}
                    </IconButton>
                  )}
                  {selected.length === 0 && (
                    <DashboardActions
                      setChecked={setChecked}
                      setShowConfirmDeployTooltip={setShowConfirmDeployTooltip}
                      showConfirmDeployTooltip={showConfirmDeployTooltip}
                      isModelSetupModalOpen={isModelSetupModalOpen}
                      setIsModelSetupModalOpen={setIsModelSetupModalOpen}
                      a={a}
                      shapValueWanted={shapValueWanted}
                      setShapValueWanted={setShapValueWanted}
                      setA={setA}
                      isModels={isModels}
                      setOpenActions={setOpenActions}
                      openActions={openActions}
                      row={row}
                      actions={actions}
                      setActions={setActions}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  )}
                </TableCell>
                {row.modelName && row.modelName === loadingRowName && (
                  <span
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 10,
                      width: '100%',
                      height: '100%',
                      padding: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#FFFFFFA8'
                    }}
                  >
                    <LinearProgress sx={{ width: '100%', height: '10%' }} />
                  </span>
                )}
              </TableRow>
            );
          })}
      </TableBody>
      <Dialog open={modalIsOpen} onClose={closeModal}>
        <Box>
          <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
            Add number
            <IconButton
              onClick={closeModal}
              sx={{
                position: 'absolute',
                right: 20,
                top: 10
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <Card>
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingX: '50px' }}>
                <input type="number" value={newChoice} onChange={handleNewChoice} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '30px',
                  padding: '8px',
                  width: '100%',
                  '& .MuiPaper-root': {
                    width: '100%'
                  }
                }}
              >
                <Button onClick={saveChoice}>Save Changes</Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Dialog>
    </>
  );
};

export default DashboardBody;
