import { Axios } from '../axiosInstance';

const axiosInstance = Axios();

const downloadManager = {
  downloadFile(url: any) {
    return axiosInstance.v2.post(url);
  }
};

export default downloadManager;
