import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from '@mui/material';
import { dispatch } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { IconInfoCircle } from '@tabler/icons';
import {
  classificationMetricsOptions,
  criterionClassificationOptions,
  criterionRegressionOptions,
  featuresOptions,
  penaltyOptions,
  regressionMetricsOptions
} from '../../helpers/managers';
import { isNaN } from 'formik';

export interface ModelInfoDialogProps {
  modelInfo: string;
  problemType: string;
  params: any;
  setParams: any;
  paramsRandom: any;
  setParamsRandom: any;
  randomSaveObject: any;
  setRandomSaveObject: any;
  randomReset: any;
}

const RandomModelDialog = ({
  modelInfo,
  problemType,
  params,
  setParamsRandom,
  randomSaveObject,
  setRandomSaveObject,
  randomReset
}: ModelInfoDialogProps) => {
  const theme = useTheme();

  const initialValues = {
    randomToggle: true,
    metric: problemType === 'classification' ? classificationMetricsOptions[0] : regressionMetricsOptions[0],
    time: 0.1,
    randomFeatures: 'sqrt',
    randomNumberOfTrees: 100,
    randomMaximumDepth: 6,
    randomMaximumLeafNodes: 2,
    randomCriterion:
      problemType === 'classification' ? criterionClassificationOptions[0] : criterionRegressionOptions[0]
  };
  const [randomToggle, setRandomToggle] = useState(randomSaveObject.randomToggle ?? initialValues.randomToggle);
  const [randomMetrics, setRandomMetrics] = useState(randomSaveObject.metric ?? initialValues.metric);
  const [randomTime, setRandomTime] = useState(randomSaveObject.time ?? initialValues.time);
  const [randomFeatures, setRandomFeatures] = useState(randomSaveObject.randomFeatures ?? initialValues.randomFeatures);
  const [randomCriterion, setRandomCriterion] = useState(
    randomSaveObject.randomCriterion ?? initialValues.randomCriterion
  );
  const [randomNumberOfTrees, setRandomNumberOfTrees] = useState(
    randomSaveObject.randomNumberOfTrees ?? initialValues.randomNumberOfTrees
  );
  const [randomMaximumDepth, setRandomMaximumDepth] = useState(
    randomSaveObject.randomMaximumDepth ?? initialValues.randomMaximumDepth
  );
  const [randomMaximumLeafNodes, setRandomMaximumLeafNodes] = useState(
    randomSaveObject.randomMaximumLeafNodes ?? initialValues.randomMaximumLeafNodes
  );
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if (randomToggle) {
      if (randomMetrics === undefined || !randomMetrics || isNaN(randomTime)) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    } else {
      if (
        isNaN(randomFeatures) ||
        isNaN(randomNumberOfTrees) ||
        isNaN(randomMaximumDepth) ||
        isNaN(randomMaximumLeafNodes) ||
        isNaN(randomCriterion)
      ) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    }
  }, [
    randomMetrics,
    randomTime,
    randomFeatures,
    randomNumberOfTrees,
    randomMaximumDepth,
    randomMaximumLeafNodes,
    randomCriterion,
    randomToggle
  ]);

  const handleReset = () => {
    setRandomMetrics(initialValues.metric);
    setRandomTime(initialValues.time);
    setRandomFeatures(initialValues.randomFeatures);
    setRandomNumberOfTrees(initialValues.randomNumberOfTrees);
    setRandomMaximumDepth(initialValues.randomMaximumDepth);
    setRandomMaximumLeafNodes(initialValues.randomMaximumLeafNodes);
    setRandomCriterion(initialValues.randomCriterion);
    randomReset({});
  };

  const handleFeatures = (e: any) => {
    const value = e.target.outerText;
    setRandomFeatures(value);
  };

  const handleCriterion = (e: any) => {
    const value = e.target.outerText;
    setRandomCriterion(value);
  };

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.RandomInfoModal));
  };

  const handleRandomToggle = () => {
    setRandomToggle(!randomToggle);
  };

  const handleRandomMetrics = (e: any) => {
    const value = e.target.outerText;
    setRandomMetrics(value);
  };

  const handleRandomMaxTime = (e: any) => {
    const value = parseFloat(e.target.value);
    setRandomTime(value);
  };

  const handleRandomNumberOfTrees = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 10), 500);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setRandomNumberOfTrees(value);
  };

  const handleRandomMaxDepth = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 3), 10);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setRandomMaximumDepth(value);
  };

  const handleRandomMaxNodes = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 2), 50);

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setRandomMaximumLeafNodes(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === '.') {
      e.preventDefault();
    }
  };

  const onSaveClick = () => {
    setRandomSaveObject({
      randomToggle: randomToggle,
      metric: typeof randomMetrics === 'object' ? randomMetrics.label : randomMetrics,
      time: randomTime,
      max_features: randomFeatures,
      randomNumberOfTrees: randomNumberOfTrees,
      randomMaximumDepth: randomMaximumDepth,
      randomMaximumLeafNodes: randomMaximumLeafNodes,
      criterion: typeof randomCriterion === 'object' ? randomCriterion.label : randomCriterion
    });
    if (randomToggle) {
      setParamsRandom(() => ({
        ...params,
        models: {
          [modelInfo.replace(/\s+/g, '')]: {
            one_model: !randomToggle,
            time: randomTime,
            metric: typeof randomMetrics === 'object' ? randomMetrics.label : randomMetrics
          }
        }
      }));
    } else {
      setParamsRandom(() => ({
        ...params,
        models: {
          [modelInfo.replace(/\s+/g, '')]: {
            one_model: !randomToggle,
            max_features: randomFeatures,
            n_estimators: randomNumberOfTrees,
            max_depth: randomMaximumDepth,
            min_samples_split: randomMaximumLeafNodes,
            criterion: typeof randomCriterion === 'object' ? randomCriterion.label : randomCriterion
          }
        }
      }));
    }
    onModalClose();
  };
  console.log([modelInfo]);
  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <div style={{ maxHeight: '100%' }}>
        <DialogTitle style={{ textAlign: 'center', position: 'relative', cursor: 'move' }} id="draggable-dialog-title">
          {`${modelInfo} Model Config`}
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardContent sx={{ pt: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
              <Tooltip title="The One Model  trains a specific model based on the parameters specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
              <div style={{ color: '#000', fontSize: '22px', marginRight: '10px' }}>One Model</div>
              <div
                style={{
                  width: '70px',
                  height: '35px',
                  borderRadius: '35px',
                  backgroundColor: randomToggle ? 'green' : 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  cursor: 'pointer',
                  padding: '0 5px',
                  boxSizing: 'border-box'
                }}
                onClick={handleRandomToggle}
              >
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    transform: randomToggle ? 'translateX(38px)' : 'translateX(0)',
                    transition: 'transform 0.2s ease-in-out',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div style={{ color: '#000', fontSize: '22px', marginLeft: '10px' }}>Best Model</div>
              <Tooltip title="The  Best Model gives the leading model determined by the algorithm based on the Metrics  and Time specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
            </div>
            {randomToggle ? (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Metrics</p>
                      <Tooltip title="Select one of this parameters, which should be taken into account for model convergence.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={
                        problemType === 'classification' ? classificationMetricsOptions : regressionMetricsOptions
                      }
                      value={typeof randomMetrics === 'object' ? randomMetrics.label : randomMetrics}
                      onChange={handleRandomMetrics}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Time</p>
                      <Tooltip title="Select how long you are able to expect for completion of model training process in hours.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      value={randomTime}
                      onChange={handleRandomMaxTime}
                      placeholder="Select Time"
                      inputProps={{ type: 'number', min: 0.1 }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Number of Trees</p>
                      <Tooltip title="Specifies the number of trees in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleRandomNumberOfTrees}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={randomNumberOfTrees}
                      inputProps={{
                        min: 10,
                        step: 1,
                        type: 'number',
                        max: 500
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum features</p>
                      <Tooltip title="Specifies the maximum number of features to consider when looking for the best split in a single tree.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={featuresOptions}
                      value={randomFeatures}
                      onChange={handleFeatures}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>

                  {/*<Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>*/}
                  {/*  <div style={{ display: 'flex', alignItems: 'center' }}>*/}
                  {/*    <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum features</p>*/}
                  {/*    <Tooltip title="Specifies the maximum number of features to consider when looking for the best split in a single tree.">*/}
                  {/*      <div>*/}
                  {/*        <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />*/}
                  {/*      </div>*/}
                  {/*    </Tooltip>*/}
                  {/*  </div>*/}
                  {/*  <TextField*/}
                  {/*    style={{ width: '100%' }}*/}
                  {/*    onChange={handleRandomLearningRate}*/}
                  {/*    type="text"*/}
                  {/*    // onKeyDown={handleKeyDown}*/}
                  {/*    value={randomLearningRate}*/}
                  {/*    inputProps={{*/}
                  {/*      min: 0.00001,*/}
                  {/*      step: 0.00001,*/}
                  {/*      type: 'number',*/}
                  {/*      max: 0.99999*/}
                  {/*    }}*/}
                  {/*  />*/}
                  {/*</Grid>*/}
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum depth </p>
                      <Tooltip title="Specifies the maximum depth of each tree in the model">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleRandomMaxDepth}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={randomMaximumDepth}
                      inputProps={{
                        min: 3,
                        step: 1,
                        type: 'number',
                        max: 10
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Minimum samples split</p>
                      <Tooltip title="Specifies the minimum number of samples required to split an internal node.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleRandomMaxNodes}
                      type={'number'}
                      onKeyDown={handleKeyDown}
                      value={randomMaximumLeafNodes}
                      inputProps={{
                        min: 2,
                        step: 1,
                        type: 'number',
                        max: 50
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Criterion</p>
                      <Tooltip title="Specifies the function used to measure the quality of a split․">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={
                        problemType === 'classification' ? criterionClassificationOptions : criterionRegressionOptions
                      }
                      value={randomCriterion}
                      onChange={handleCriterion}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>
                </Grid>
              </div>
            )}

            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: '20px auto',
                    height: '52px',
                    background: theme.palette.warning.dark,
                    '&:hover': { background: theme.palette.warning.main },
                    color: 'grey.900'
                  }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  style={{
                    backgroundColor: disableSave ? '#CCCCCC' : '#202090',
                    color: disableSave ? '#666666' : '#fff'
                  }}
                  sx={{ margin: '20px auto', height: '52px' }}
                  onClick={onSaveClick}
                  disabled={disableSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Dialog>
  );
};

export default RandomModelDialog;
