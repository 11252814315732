import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import {
  backButtonStyles,
  buttonContainerStyles,
  buttonStyles,
  containerStyles,
  descriptionStyles,
  formContainerStyles,
  formStyles,
  inputStyles,
  nextButtonStyles,
  radioButtonStyles,
  scrollBlockStyles,
  span1Styles,
  spanLastStyles,
  spanStyles,
  titleStyles
} from './Step32.styles';
import { IBlock, IStep3 } from './Step32.models';
import { contentStyles } from '../Step1/Step1.styles';
import { PlusIconButton } from '../../../../components/Buttons/setupModelPlusButton';
import Step3Inner2 from './step3Inner2/Step3Inner2.component';
import { emptyBlock } from './Step32.helpers';
import { INanColumn } from '../../ModelSetupModal.models';
import { removeLastExtension } from '../../../../helpers/removeExtension';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  '@global': {
    '::-webkit-scrollbar': {
      width: '8px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }
});

const Step32: React.FC<IStep3> = ({
  arrayOfNumericalCategoricalNANColumns,
  arrayOfNumericalColumns,
  step1Data,
  step2Data,
  step3Data,
  setStep3Data,
  setActiveStep
}) => {
  useStyles();
  const [dropdownOptions, setDropdownOptions] = useState<INanColumn[]>([]);
  const [blocks, setBlocks] = useState<IBlock[]>(step3Data.blocks.length ? step3Data.blocks : [emptyBlock({ id: 1 })]);

  const [isNAIYesOptionChecked, toggleISNAIOption] = useState(step3Data.isNAIYesOptionChecked);

  function isFormValid() {
    return blocks.every((block) => {
      // Check if inputValue exists
      if (!block.inputValue) {
        return false;
      }

      // Check if radioButtonValue exists
      if (!block.radioButtonValue) {
        return false;
      }

      // If radioButtonValue is 'custom value', ensure customValue is not empty
      if (block.radioButtonValue === 'custom value' && !block.customValue) {
        return false;
      }

      return true;
    });
  }

  const onPlusClick = () => {
    if (!blocks.length) {
      setBlocks([emptyBlock()]);
    } else if (blocks.length < 10) {
      setBlocks((prev) => [...prev, emptyBlock({ id: blocks[blocks.length - 1].id + 1 })]);
    }
  };

  function updateCurrentBlock(currentBlock: IBlock) {
    const newBlocks = blocks.map((block) => {
      if (block.id === currentBlock.id) {
        return { ...currentBlock };
      }

      return { ...block };
    });

    setBlocks(newBlocks);
  }

  const deleteBlock = (blockId: any, key: string) => {
    setBlocks((prev) => prev.filter((block) => block.id !== blockId));
  };

  function onBackClick() {
    setActiveStep(2);
    if (isNAIYesOptionChecked) {
      setStep3Data({ isNAIYesOptionChecked, blocks });
    }
  }

  function isNANoOptionChange() {
    setStep3Data({ isNAIYesOptionChecked: false, blocks: [] });
    setBlocks([emptyBlock({ id: 1 })]);
    toggleISNAIOption(false);
  }

  function isNAYesOptionChange() {
    setStep3Data({ isNAIYesOptionChecked: true, blocks: [] });
    toggleISNAIOption(true);
  }

  //todo

  function onSubmit() {
    setActiveStep(4);
    if (isNAIYesOptionChecked) {
      setStep3Data({ isNAIYesOptionChecked, blocks });
    }
  }

  useEffect(() => {
    const newDropdownOptions = arrayOfNumericalCategoricalNANColumns.filter(
      (option: any) =>
        !blocks.some((block) => block.inputValue === option.name) && option.name !== step2Data.targetColumn
    );
    setDropdownOptions(newDropdownOptions);
  }, [blocks, arrayOfNumericalCategoricalNANColumns, step2Data.targetColumn]);

  return (
    <div style={formContainerStyles}>
      <Grid item xs={12} md={12} style={containerStyles}>
        <div style={contentStyles}>
          <Grid item xs={12}>
            <h2 style={titleStyles}>NA Handling</h2>
          </Grid>
          <>
            {Object.keys(arrayOfNumericalCategoricalNANColumns).length === 0 ? (
              <span style={span1Styles}>
                The '{removeLastExtension(step1Data.uploadedFile)}' does not contain any NA values.
              </span>
            ) : (
              <>
                <div style={scrollBlockStyles}>
                  <Grid item xs={12}>
                    <span style={descriptionStyles}>Have you implemented any NA handling?</span>
                  </Grid>
                  <Grid item xs={12} md={12} style={formStyles}>
                    <label style={radioButtonStyles}>
                      <input
                        type="radio"
                        name="naHandling"
                        value="no"
                        onChange={isNANoOptionChange}
                        style={inputStyles}
                        checked={isNAIYesOptionChecked === false}
                      />
                      <span style={spanStyles}>No</span>
                    </label>
                    <label style={radioButtonStyles}>
                      <input
                        type="radio"
                        name="naHandling"
                        value="yes"
                        onChange={isNAYesOptionChange}
                        style={inputStyles}
                        checked={isNAIYesOptionChecked === true}
                      />
                      <span style={spanLastStyles}>Yes</span>
                    </label>
                  </Grid>
                  {isNAIYesOptionChecked && blocks.length > 0 ? (
                    <>
                      <div>
                        {blocks.map((block) => (
                          <div key={block.id}>
                            <Step3Inner2
                              firstLabel="Select Column"
                              dropDownOptions={dropdownOptions}
                              block={block}
                              blocks={blocks}
                              updateCurrentBlock={updateCurrentBlock}
                              deleteBlock={deleteBlock}
                            />
                          </div>
                        ))}
                      </div>
                      <PlusIconButton blocks={blocks} handlePlusButtonClick={onPlusClick} title="Add a new column" />
                    </>
                  ) : null}
                </div>
              </>
            )}
          </>
        </div>
        <Grid item xs={12} md={12} sx={buttonContainerStyles}>
          <Button variant="contained" size="large" style={backButtonStyles} sx={buttonStyles} onClick={onBackClick}>
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            style={{
              // backgroundColor:
              //     isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0
              //       ? '#d9d9d9'
              //       : '#334063',
              //   color:
              //     isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0 ? '#635F5F' : '#fff',
              backgroundColor:
                (isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0) ||
                (isNAIYesOptionChecked === true && !isFormValid())
                  ? '#d9d9d9'
                  : '#334063',
              color:
                (isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0) ||
                (isNAIYesOptionChecked === true && !isFormValid())
                  ? '#635F5F'
                  : '#fff',
              width: '120px',
              height: '60px',
              borderRadius: '8px',
              fontFamily: 'Circular Std Book',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: 'normal',
              float: 'right'
            }}
            sx={buttonStyles}
            onClick={onSubmit}
            // disabled={isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0}
            disabled={
              (isNAIYesOptionChecked === null && arrayOfNumericalCategoricalNANColumns.length > 0) ||
              (isNAIYesOptionChecked === true && !isFormValid())
            }
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Step32;
