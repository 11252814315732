import React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export const formContainerStyles: React.CSSProperties = {
  width: '100%'
};

export const containerStyles: React.CSSProperties = {
  flexDirection: 'column',
  padding: '0 50px',
  display: 'flex',
  color: '#000'
};

export const scrollBlockStyles: React.CSSProperties = {
  height: '390px',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: '100%',
  scrollbarWidth: 'thin', // Firefox
  scrollbarColor: '#888 #f1f1f1' // Firefox
};

export const globalStyles = {
  // Styles for WebKit browsers
  '::-webkit-scrollbar': {
    width: '8px'
  },
  '::-webkit-scrollbar-track': {
    background: '#f1f1f1',
    borderRadius: '10px'
  },
  '::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '10px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
};

export const contentStyles: React.CSSProperties = {
  maxHeight: '500px',
  minHeight: '500px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '73%',
  margin: '0 auto'
};

export const titleStyles: React.CSSProperties = {
  marginBottom: '40px',
  marginTop: '0px',
  display: 'flex',
  justifyContent: 'center',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const formStyles: React.CSSProperties = {
  // marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center'
};
export const formStyles1: React.CSSProperties = {
  marginTop: '30px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center'
};

export const hintStyles: React.CSSProperties = {
  marginTop: '365px',
  display: 'flex',
  justifyContent: 'center',
  color: 'rgb(51, 64, 99)',
  fontStyle: 'italic',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: 'normal'
};

export const dropdownStyles: React.CSSProperties = {
  marginTop: '40px',
  marginRight: '20px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
};
export const autocompleteStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end'
};

export const radioButtonStyles: React.CSSProperties = {
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'center'
};
export const inputStyles: React.CSSProperties = {
  width: '18px',
  height: '18px',
  fill: '#FFF',
  strokeWidth: '1px',
  stroke: '#000',
  marginRight: '5px',
  accentColor: '#334063'
};

export const spanStyles: React.CSSProperties = {
  color: '#000',
  marginBottom: '10px',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  display: 'flex',
  justifyContent: 'center',
  marginRight: '45px',
  paddingTop: '4px'
};

export const span1Styles: React.CSSProperties = {
  color: '#E9271B',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  fontFamily: 'Circular Std Book',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  textAlign: 'center',
  paddingTop: '4px'
};

export const labelStyles: React.CSSProperties = {
  display: 'flex'
  // alignItems: 'center'
};

export const labelStylesLastChild: React.CSSProperties = {
  display: 'flex',
  // alignItems: 'center',
  marginLeft: '10px'
};

export const backButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff'
};
export const descriptionStyles: React.CSSProperties = {
  marginTop: '5px',
  marginBottom: '10px',
  display: 'flex',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center'
};

export const marginStyles: React.CSSProperties = {
  marginTop: '30px',
  marginBottom: '10px',
  display: 'flex',
  color: '#000',
  fontFamily: 'Circular Std Book',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center'
};

export const description1Styles: React.CSSProperties = {
  marginTop: '20px',
  display: 'flex',
  color: 'red',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  justifyContent: 'center',
  textAlign: 'center'
};

export const questionOptionsStyles: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center'
};

export const noOptionStyles: React.CSSProperties = {
  flexDirection: 'row',
  display: 'flex',
  color: 'rgb(0, 0, 0)',
  marginTop: '20px',
  justifyContent: 'center',
  width: '97%'
};

export const nextButtonStyles: React.CSSProperties = {
  width: '120px',
  height: '60px',
  borderRadius: '8px',
  fontFamily: 'Circular Std Book',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  backgroundColor: '#334063',
  color: '#fff',
  float: 'right'
};

export const buttonContainerStyles: SxProps<Theme> = {};

export const buttonStyles: SxProps<Theme> = {
  margin: '20px auto',
  height: '52px'
};
