import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  TextField
} from '@mui/material';
import { dispatch } from '../../store/hooks';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { IconInfoCircle } from '@tabler/icons';
import {
  classificationMetricsOptions,
  penaltyOptions,
  regressionMetricsOptions,
  solverOptions
} from '../../helpers/managers';
import { useTheme } from '@mui/material/styles';
import { isNaN } from 'formik';

export interface ModelInfoDialogProps {
  modelInfo: string;
  problemType: string;
  params: any;
  setParams: any;
  paramsLR: any;
  setParamsLR: any;
  logisticSaveObject:any;
  setLogisticSaveObject:any;
  logisticReset:any;
}

const LogisticRegressionDialog = ({
  modelInfo,
  problemType,
  params,
  setParams,
  paramsLR,
  setParamsLR,
  logisticSaveObject,
  setLogisticSaveObject,
  logisticReset,
}: ModelInfoDialogProps) => {
  const theme = useTheme();

  const initialValues = {
    logisticToggle: true,
    metric: problemType==='classification' ? classificationMetricsOptions[0] : regressionMetricsOptions[0],
    time: 0.1,
    logisticIterations: 100,
    logisticC: 1,
    logisticPenalty: penaltyOptions[2],
    logisticSolver: solverOptions[0]
  }

  const [logisticToggle, setLogisticToggle] = useState(logisticSaveObject.logisticToggle ?? initialValues.logisticToggle);
  const [logisticMetrics, setLogisticMetrics] = useState(logisticSaveObject.metric ?? initialValues.metric);
  const [logisticTime, setLogisticTime] = useState(logisticSaveObject.time ?? initialValues.time);
  const [logisticIterations, setLogisticIterations] = useState(logisticSaveObject.logisticIterations ?? initialValues.logisticIterations);
  const [logisticC, setLogisticC] = useState(logisticSaveObject.logisticC ?? initialValues.logisticC);
  const [logisticPenalty, setLogisticPenalty] = useState(logisticSaveObject.logisticPenalty ?? initialValues.logisticPenalty);
  const [logisticSolver, setLogisticSolver] = useState(logisticSaveObject.logisticSolver ?? initialValues.logisticSolver);
  const [filteredSolverOptions, setFilteredSolverOptions] = useState(solverOptions);
  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    if(logisticToggle) {
      if (logisticMetrics === undefined || !logisticMetrics || isNaN(logisticTime)) {
        setDisableSave(true)
      }else{
        setDisableSave(false)
      }
    }else{
      if (isNaN(logisticIterations) || isNaN(logisticC) || logisticPenalty === undefined || !logisticPenalty || logisticSolver === undefined || !logisticSolver) {
        setDisableSave(true)
      }else{
        setDisableSave(false)
      }
    }
  },[logisticMetrics,logisticTime,logisticIterations,logisticC,logisticPenalty,logisticSolver,logisticToggle]);


  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.LogisticRegressionInfoModal));
  };

  const handleReset = () => {
    // setLogisticToggle(initialValues.logisticToggle)
    setLogisticMetrics(initialValues.metric);
    setLogisticTime(initialValues.time);
    setLogisticIterations(initialValues.logisticIterations);
    setLogisticC(initialValues.logisticC);
    setLogisticPenalty(initialValues.logisticPenalty);
    setLogisticSolver(initialValues.logisticSolver);
    logisticReset({})
  };

  const handleLogisticToggle = () => {
    // setParams((prevParams: any) => ({
    //   ...prevParams,
    //   models: {
    //     ...prevParams.models,
    //     NeuralNets: {
    //       ...prevParams.models.NeuralNets,
    //       one_model: logisticToggle
    //     }
    //   }
    // }));
    setLogisticToggle(!logisticToggle);
  };

  const handlePenalty = (e: any) => {
    const value = e.target.outerText;
    setLogisticPenalty(value);

    // Filter solver options based on the selected penalty
    const options = getFilteredSolverOptions(value);
    setFilteredSolverOptions(options);
    setLogisticSolver(options[0]); // Set the default solver from the filtered options
  };

  const handleSolver = (e: any) => {
    const value = e.target.outerText;
    setLogisticSolver(value);
  };

  const getFilteredSolverOptions = (penalty: string) => {
    switch (penalty) {
      case 'none':
        return solverOptions.filter((solver) => ['lbfgs', 'newton-cg', 'saga'].includes(solver.label));
      case 'l1':
        return solverOptions.filter((solver) => ['liblinear', 'saga'].includes(solver.label));
      case 'l2':
        return solverOptions.filter((solver) =>
          ['lbfgs', 'liblinear', 'newton-cg', 'saga'].includes(solver.label)
        );
      case 'elasticnet':
        return solverOptions.filter((solver) => ['saga'].includes(solver.label));
      default:
        return solverOptions; // Default to all solver options
    }
  };

  const handleLogisticMetrics = (e: any) => {
    const value = e.target.outerText;
    setLogisticMetrics(value);
  };

  const handleLogisticMaxTime = (e: any) => {
    const value = parseFloat(e.target.value);
    setLogisticTime(value);
  };

  const handleLogisticMaxIteration = (e: any) => {
    let value = parseInt(e.target.value);
    value = Math.min(Math.max(value, 1), 1000);
    // Update the input value if it was modified

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    setLogisticIterations(value);
  };

  const handleLogisticC = (e: any) => {
    let value = parseFloat(e.target.value);
    value = Math.min(Math.max(value, 0.00001), 100000);
    // Update the input value if it was modified

    if (value !== parseInt(e.target.value, 10)) {
      e.target.value = value;
    }
    // const number = parseInt(value, 10)
    setLogisticC(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '-' || e.key === 'e' || e.key === '+' || e.key === '.') {
      e.preventDefault();
    }
  };

  const onSaveClick = () => {
    setLogisticSaveObject({
        logisticToggle:logisticToggle,
        metric: typeof logisticMetrics === 'object' ? logisticMetrics.label : logisticMetrics,
        time: logisticTime,
        logisticIterations: logisticIterations,
        logisticC: logisticC,
        logisticPenalty: logisticPenalty,
        logisticSolver: logisticSolver
      }
    )
    if (logisticToggle) {
      setParamsLR(() => ({
        ...params,
        models: {
          [modelInfo.replace(/\s+/g, '')]: {
            one_model: !logisticToggle,
            time: logisticTime,
            metric: typeof logisticMetrics ==='object' ? logisticMetrics.label : logisticMetrics
          }
        }
      }));
    } else {
      setParamsLR(() => ({
        ...params,
        models: {
          [modelInfo.replace(/\s+/g, '')]: {
            one_model: !logisticToggle,
            max_iter: logisticIterations,
            C: logisticC,
            penalty: typeof logisticPenalty ==='object' ? logisticPenalty.label : logisticPenalty,
            solver: typeof logisticSolver ==='object' ? logisticSolver.label : logisticSolver
          }
        }
      }));
    }
    if(!logisticToggle && problemType === 'regression'){
      setParamsLR(() => ({
        ...params,
        models: {
          [modelInfo.replace(/\s+/g, '')]: {
            one_model: true,
            fit_intercept:true
          }
        }
      }));
    }
    onModalClose();
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <div style={{ maxHeight: '100%' }}>
        <DialogTitle style={{ textAlign: 'center', position: 'relative', cursor: 'move' }} id="draggable-dialog-title">
          {`${modelInfo} Model Config`}
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Card>
          <CardContent sx={{ pt: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px', paddingTop: '10px' }}>
              <Tooltip title="The One Model  trains a specific model based on the parameters specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
              <div style={{ color: '#000', fontSize: '22px', marginRight: '10px' }}>One Model</div>
              <div
                style={{
                  width: '70px',
                  height: '35px',
                  borderRadius: '35px',
                  backgroundColor: logisticToggle ? 'green' : 'green',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  cursor: 'pointer',
                  padding: '0 5px',
                  boxSizing: 'border-box'
                }}
                onClick={handleLogisticToggle}
              >
                <div
                  style={{
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                    transform: logisticToggle ? 'translateX(38px)' : 'translateX(0)',
                    transition: 'transform 0.2s ease-in-out',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer'
                  }}
                />
              </div>
              <div style={{ color: '#000', fontSize: '22px', marginLeft: '10px' }}>Best Model</div>
              <Tooltip title="The  Best Model gives the leading model determined by the algorithm based on the Metrics  and Time specified by the user.">
                <div
                  style={{
                    marginTop: '-15px'
                  }}
                >
                  <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                </div>
              </Tooltip>
            </div>
            {logisticToggle ? (
              <div>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Metrics</p>
                      <Tooltip title="Select one of this parameters, which should be taken into account for model convergence.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <Autocomplete
                      style={{ width: '100%' }}
                      options={
                        problemType === 'classification' ? classificationMetricsOptions : regressionMetricsOptions
                      }
                      value={logisticMetrics}
                      onChange={handleLogisticMetrics}
                      renderInput={(params) => <TextField {...params} label="" />}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Time</p>
                      <Tooltip title="Select how long you are able to expect for completion of model training process in hours.">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      defaultValue={logisticTime}
                      value={logisticTime}
                      onChange={handleLogisticMaxTime}
                      placeholder="Select Time"
                      inputProps={{ type: 'number', min: 0.1 }}
                    />
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div>
                {problemType === 'classification' && <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  // spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '600' }}>Penalty</p>
                        <Tooltip title="Specify the norm of the penalty.">
                          <div>
                            <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                          </div>
                        </Tooltip>
                      </div>
                      <Autocomplete
                        style={{ width: '100%' }}
                        options={penaltyOptions}
                        value={logisticPenalty}
                        onChange={handlePenalty}
                        renderInput={(params) => <TextField {...params} label="" />}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ fontSize: '18px', fontWeight: '600' }}>Solver</p>
                        <Tooltip title="Specify the algorithm to use in the optimization problem.">
                          <div>
                            <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                          </div>
                        </Tooltip>
                      </div>
                      <Autocomplete
                        style={{ width: '100%' }}
                        options={filteredSolverOptions}
                        value={logisticSolver}
                        onChange={handleSolver}
                        renderInput={(params) => <TextField {...params} label="" />}
                      />
                    </Grid>

                  </Grid>
                </Grid>}
                {problemType === 'classification' && <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                  style={{ paddingBottom: '10px' }}
                >
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>Maximum iterations </p>
                      <Tooltip title="Maximum number of iterations taken for the solvers to converge">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleLogisticMaxIteration}
                      type='number'
                      // onKeyDown={handleKeyDown}
                      defaultValue={logisticIterations}
                      value={logisticIterations}
                      inputProps={{
                        min: 1,
                        step: 1,
                        type: 'number',
                        max: 1000
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ paddingX: '16px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <p style={{ fontSize: '18px', fontWeight: '600' }}>C</p>
                      <Tooltip title="Inverse of regularization strength; must be a positive float">
                        <div>
                          <IconInfoCircle size={16} style={{ color: '#000', fill: '#fff' }} />
                        </div>
                      </Tooltip>
                    </div>
                    <TextField
                      style={{ width: '100%' }}
                      onChange={handleLogisticC}
                      type={'number'}
                      // onKeyDown={handleKeyDown}
                      defaultValue={logisticC}
                      value={logisticC}
                      inputProps={{
                        min: 0.00001,
                        step: 1,
                        type: 'number',
                        max: 100000,
                        title: ''
                      }}
                    />
                  </Grid>
                </Grid>}
              </div>
            )}
            <Grid item xs={12} md={4} sx={{ paddingX: '16px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {(problemType === 'classification'  || (problemType === 'regression' && logisticToggle)) && <Button
                  variant="contained"
                  size="large"
                  sx={{
                    margin: '20px auto',
                    height: '52px',
                    background: theme.palette.warning.dark,
                    '&:hover': { background: theme.palette.warning.main },
                    color: 'grey.900'
                  }}
                  onClick={handleReset}
                >
                  Reset
                </Button>
                }
                <Button
                  variant="contained"
                  size="large"
                  style={{backgroundColor: disableSave ? '#CCCCCC' : '#202090',color: disableSave ? '#666666' : '#fff'}}
                  sx={{ margin: '20px auto', height: '52px' }}
                  onClick={onSaveClick}
                  disabled={disableSave}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Dialog>
  );
};

export default LogisticRegressionDialog;
