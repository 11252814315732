import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { ModelActions } from '../../components/MainDashboard/Actions/ModelActions';
import MainDashboard from '../../components/MainDashboard/index';
import { ModelManagerDashboardHeader, ModelManagerDashboardSearch } from '../../helpers/dashboardData';
import LineChart from '../../components/Charts/LineChart';
import AnalyzeManagerTable from '../../components/AnalyzeManagerTable';
import { dispatch, useAppSelector } from '../../store/hooks';
import { userModelsMiddleware, userModelsSelector } from '../../store/slices/userModels';
import { modelSummarySelector } from '../../store/slices/modelSummary';

const AnalyzeManager = () => {
  const [analyzeManagerTableData, setAnalyzeManagerTableData] = useState<any[]>([]);
  const [analyzeManagerChartData, setAnalyzeManagerChartData] = useState<Object>({});
  const [activeTab, setActiveTab] = useState('ModelMetrics');
  const [rowDataStatus, setRowDataStatus] = useState({});

  const { userModelsData } = useAppSelector(userModelsSelector.userModelsData);
  const { modelSummaryData } = useAppSelector(modelSummarySelector.modelSummaryData);
  const getUserModelsURL = `/datasource/user_models?container_name=containerfromblobservice${localStorage.getItem(
    'userId'
  )}`;
  const [userModelsArray, setUserModelsArray] = useState<any>([]);
  useEffect(() => {
    dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
  }, []);

  useEffect(() => {
    if (userModelsData && userModelsData.length > 0) {
      const newArray1 = userModelsData.map((object: any, index: any) => {
        return { id: index + 1, ...object };
      });
      setUserModelsArray(newArray1);
    } else {
      setUserModelsArray([]);
    }
  }, [userModelsData]);

  const allAreClassification = analyzeManagerTableData && analyzeManagerTableData?.length > 0 && analyzeManagerTableData?.every(item => item.Problem_type === 'classification');
  const containsBoth = analyzeManagerTableData && analyzeManagerTableData?.length > 0 && analyzeManagerTableData?.some(item => item.Problem_type === 'classification') && analyzeManagerTableData?.some(item => item.Problem_type === 'regression');

  return (
    <Box>
      <Grid style={{ marginBottom: '20px' }} container spacing={2} columns={12}>
        <div>
          <button
            style={
              activeTab === 'ModelMetrics'
                ? {
                    backgroundColor: '#2b2d42',
                    color: '#fff',
                    marginRight: '20px',
                    border: 'none',
                    borderRadius: '9px',
                    fontSize: '24px',
                    padding: '10px'
                  }
                : { marginRight: '20px', border: 'none', fontSize: '12px', padding: '10px' }
            }
            onClick={() => setActiveTab('ModelMetrics')}
          >
            Model Evaluation parameters
          </button>
          {/*<button*/}
          {/*  style={*/}
          {/*    activeTab === 'FeatureImportance'*/}
          {/*      ? {*/}
          {/*          backgroundColor: '#337ab7',*/}
          {/*          color: '#fff',*/}
          {/*          marginRight: '20px',*/}
          {/*          border: 'none',*/}
          {/*          fontSize: '24px',*/}
          {/*          padding: '10px'*/}
          {/*        }*/}
          {/*      : { marginRight: '20px', border: 'none', fontSize: '12px', padding: '10px' }*/}
          {/*  }*/}
          {/*  onClick={() => setActiveTab('FeatureImportance')}*/}
          {/*>*/}
          {/*  Feature importance*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  style={*/}
          {/*    activeTab === 'DistributionOfPredictions'*/}
          {/*      ? {*/}
          {/*          backgroundColor: '#337ab7',*/}
          {/*          color: '#fff',*/}
          {/*          marginRight: '20px',*/}
          {/*          border: 'none',*/}
          {/*          fontSize: '24px',*/}
          {/*          padding: '10px'*/}
          {/*        }*/}
          {/*      : { marginRight: '20px', border: 'none', fontSize: '12px', padding: '10px' }*/}
          {/*  }*/}
          {/*  onClick={() => setActiveTab('DistributionOfPredictions')}*/}
          {/*>*/}
          {/*  Distribution of prediction*/}
          {/*</button>*/}
        </div>
      </Grid>
      {activeTab === 'ModelMetrics' && (
        <div>
        {analyzeManagerTableData && analyzeManagerTableData.length > 0 && containsBoth ?
          (<div style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '50px 0',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '32px'
          }}>Classification and Regression models are not comparable.</div>) : (
        <Grid container spacing={2} columns={12}>
          <Grid item xs={allAreClassification? 6 : 12} style={{ padding: '68px 5px' }}>
            {/*<h2>Model Evaluation parameters</h2>*/}
            <Box style={{ backgroundColor: '#EDF2F4', overflow: 'auto', maxHeight: '365px' }}>
              <AnalyzeManagerTable
                analyzeManagerTableData={analyzeManagerTableData}
                setAnalyzeManagerTableData={setAnalyzeManagerTableData}
              />
            </Box>
          </Grid>
          {allAreClassification &&
          <Grid item xs={6} style={{ padding: '16px 5px' }}>
            <h2>ROC Curve Analysis</h2>
            <Box style={{ backgroundColor: '#EDF2F4' }}>
              <LineChart
                modelSummaryData={modelSummaryData}
                analyzeManagerChartData={{ ...analyzeManagerChartData }}
                setAnalyzeManagerChartData={setAnalyzeManagerChartData}
              />
            </Box>
          </Grid>
          }
        </Grid>)}
        </div>
      )}
      <MainDashboard
        rowDataStatus={rowDataStatus}
        setRowDataStatus={setRowDataStatus}
        isComp={true}
        isModels={true}
        analyzeManagerTableData={analyzeManagerTableData}
        setAnalyzeManagerTableData={setAnalyzeManagerTableData}
        analyzeManagerChartData={analyzeManagerChartData}
        setAnalyzeManagerChartData={setAnalyzeManagerChartData}
        minimize={false}
        title="My Models"
        data={userModelsArray} //ModelManagerData
        tableHeaders={ModelManagerDashboardHeader}
        actions={ModelActions}
        searchProps={ModelManagerDashboardSearch}
      />
    </Box>
  );
};

export default AnalyzeManager;
