import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Grid, IconButton } from '@mui/material';

import { DashboardActionsProps, IDashboardAction } from '../../types';
import { dispatch, useAppSelector } from '../../store/hooks';
import { downloadMiddleware, downloadSelector } from '../../store/slices/download';
import { deleteMiddleware } from '../../store/slices/delete';
import store from '../../store/store';
import API from '../../manager/API';
import { toast } from 'react-toastify';
import { deployMiddleware } from '../../store/slices/deploy';
import { deployStatusMiddleware } from '../../store/slices/deployStatus';
import { userModelsMiddleware, userModelsSelector } from '../../store/slices/userModels';
import {
  deleteDialogOpen,
  inferenceDialogOpen,
  modelViewDialogOpen,
  summaryDialogOpen,
  urlDialogOpen
} from './Actions/ModelActions';
import { modalsMiddleware } from '../../store/slices/modals';
import { SeveritiesType } from '../../types/modals';
import { undeployMiddleware } from '../../store/slices/undeploy';
import ModelSetupModalComponent from '../../modals/ModelSetupModal/ModelSetupModal.component';
import useOpen from '../../hooks/UseOpen';
import { removeLastExtension } from '../../helpers/removeExtension';

const DashboardActions = ({
  setChecked,
  setA,
  isModels,
  selected,
  setSelected,
  openActions,
  setOpenActions,
  row,
  actions,
  setActions,
  shapValueWanted,
  setShowConfirmDeployTooltip,
  showConfirmDeployTooltip
}: DashboardActionsProps) => {
  const [isLoading, setIsLoading] = useState<string | undefined>('');
  const [isDownloaded, setIsDownloaded] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  // const [showConfirmDeployTooltip, setShowConfirmDeployTooltip] = useState(false);
  const [action, setAction] = useState<IDashboardAction | null>(null);
  const [index, setIndex] = useState(0);
  const [isModelSetupModalOpen, setIsModelSetupModalOpen] = useState<boolean>(false);
  const { open, setOpen } = useOpen();

  const userId = localStorage.getItem('userId');
  const getUserModelsURL = `/datasource/user_models?container_name=containerfromblobservice${userId}`;
  const uploadedModelStatus = row.model_status === 'Uploaded';

  const openModelSetupModal = () => {
    setIsModelSetupModalOpen(true);
  };

  const closeModelSetupModal = () => {
    setIsModelSetupModalOpen(false);
  };

  const toastNotification = () => {
    dispatch(
      modalsMiddleware.setToastNotificationPopUpState({
        open: true,
        props: {
          severityType: SeveritiesType.error,
          description: 'Hello, i am error'
        }
      })
    );
  };
  const [modelActions1, setModelActions1] = useState<IDashboardAction[]>([]);
  useEffect(() => {
    setModelActions1([
      {
        id: 'Setup',
        title: 'Setup',
        name: 'setup',
        style: {
          display: !uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#FCA311',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#F77F00'
          }
        },
        color: 'primary',
        state: true
      },
      // {
      //   id: 'View',
      //   title: 'View',
      //   name: 'view',
      //   style: {
      //     display: uploadedModelStatus ? 'none' : 'block',
      //     backgroundColor: '#FCA311',
      //     color: '#ffffff',
      //     '&:hover': {
      //       backgroundColor: '#F77F00'
      //     }
      //   },
      //   color: 'primary',
      //   state: true,
      //   openFunction: modelViewDialogOpen
      // },
      {
        id: 'Summary',
        title: 'Summary',
        name: 'summary',
        style: {
          display: uploadedModelStatus || row.model_type === 'uploaded_model' ? 'none' : 'block',
          backgroundColor: '#3DA5D9',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#2364AA'
          }
        },
        color: 'primary',
        state: true,
        openFunction: summaryDialogOpen
      },
      {
        id: 'Inference',
        title: 'Predict',
        name: 'inference',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#0EAD96',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#1A936F'
          }
        },
        color: 'error',
        state: true,
        openFunction: inferenceDialogOpen
      },
      {
        id: 'View_url',
        title: 'URL',
        name: 'View URL',
        style: {
          display: uploadedModelStatus || row.model_status === 'Saved' ? 'none' : 'block',
          color: '#ffffff',
          backgroundColor: '#FCA311',
          '&:hover': {
            backgroundColor: '#F77F00'
          }
        },
        color: 'secondary',
        state: true,
        openFunction: urlDialogOpen
      },

      {
        id: row.model_status === 'Saved' ? 'Deploy' : 'Undeploy',
        title: row.model_status === 'Saved' ? 'Deploy' : 'Undeploy',
        name: 'deploy',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          color: '#ffffff',
          backgroundColor: '#0EAD96',
          '&:hover': {
            backgroundColor: '#1A936F'
          }
        },
        color: 'secondary',
        state: true,
        openFunction: toastNotification,
        valueOnClick: {
          hideMe: true,
          field: row.model_status === 'Saved' ? 'Deploy' : 'Undeploy'
        }
      },

      {
        id: 'Undeploy',
        title: 'Undeploy',
        name: 'undeploy',
        style: {
          display: uploadedModelStatus ? 'none' : 'block',
          backgroundColor: '#FCA311',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#F77F00'
          }
        },
        color: 'warning',
        state: false,
        // openFunction: deployUndeployButton,
        valueOnClick: {
          hideMe: true,
          field: 'Deploy'
        }
      },
      {
        id: 'Drop',
        title: 'Drop',
        name: 'drop',
        style: {
          backgroundColor: '#EF233C',
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#D90429'
          }
        },
        color: 'error',
        state: true,
        openFunction: deleteDialogOpen
      }
    ]);
  }, [row]);

  const pendingAction = async (isShap: boolean, action?: any, index: number = 0) => {
    const newActions = [...modelActions1];

    if (action.valueOnClick.hideMe) {
      const newValue = newActions[modelActions1.findIndex((item) => item.id === action.valueOnClick.field)];

      newActions[index].state = false;
      newValue.state = true;
      setIsLoading(newValue?.id);
    }
    const fileName = row.file_name;
    const params = {
      container_name: `containerfromblobservice${userId}`,
      model_name: fileName,
      is_shap: isShap
    };
    const deployUrl = '/model_deployment/model_deploy_setup';

    const deployData = await dispatch(deployMiddleware.getDeploy(deployUrl, params));

    if (deployData && deployData.status === 200) {
    } else {
      setIsLoading('');
      setOpenActions(-1);
      toast.error(removeLastExtension(deployData?.data.message));
    }

    if (deployData && Object.keys(deployData).length > 0 && deployData?.data?.status_update) {
      const deployStatusUrl = `/model_deployment/deploy_status/${deployData.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        try {
          const deployStatusData = await dispatch(deployStatusMiddleware.getDeployStatus(deployStatusUrl));
          if (deployStatusData && deployStatusData.status === 200) {
            if (deployStatusData && deployStatusData.data.queue_state === 'SUCCESS') {
              dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
              toast.success('Deploy successful!');
              setA(true);
              if (setActions) {
                const newActions = [...modelActions1];
                setActions(newActions);
                setIsLoading('');
              }
              clearInterval(intervalId);
              setOpenActions(-1);
            } else if (
              (deployStatusData && deployStatusData.data.queue_state === 'FAILURE') ||
              (deployStatusData && Object.keys(deployStatusData).length === 0) ||
              deployStatusData === undefined
            ) {
              setA(false);
              if (setActions) {
                const newActions = [...modelActions1];
                setActions(newActions);
                setIsLoading('');
              }
              clearInterval(intervalId);
              if (deployStatusData && Object.keys(deployStatusData).length === 0) {
                toast.warn(removeLastExtension(deployStatusData.data.message));
              } else {
                toast.error(removeLastExtension(deployStatusData.data.message));
              }
              setOpenActions(-1);
            }
          } else {
            setA(false);
            if (setActions) {
              const newActions = [...modelActions1];
              setActions(newActions);
              setIsLoading('');
            }
            clearInterval(intervalId);
            toast.error(removeLastExtension(deployStatusData?.data.message));
            setOpenActions(-1);
          }
        } catch (error: any) {
          if (setActions) {
            const newActions = [...modelActions1];
            setActions(newActions);
            setIsLoading('');
          }
          clearInterval(intervalId);
          toast.error(removeLastExtension(error?.response.data.message));
          setOpenActions(-1);
        }
      }, 5000);
    }
  };
  const handleAccept = async () => {
    setShowConfirmDeployTooltip(false);
    await pendingAction(true, action, index);
  };
  const handleDecline = async () => {
    setShowConfirmDeployTooltip(false);
    await pendingAction(false, action, index);
  };

  return (
    <div
      style={{
        display: openActions === row.id ? 'flex' : 'none',
        alignItems: 'center',
        gap: '10px'
      }}
    >
      {isModels &&
        modelActions1.map(
          (action: IDashboardAction, index) =>
            action.state && (
              <>
                {isLoading === action.id ? (
                  <Box sx={{ p: 1 }}>
                    <CircularProgress style={{ color: 'blue' }} />
                  </Box>
                ) : (
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    onClick={async (e) => {
                      if (action.title === 'Delete' || action.title === 'Summary') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Preview') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Drop') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'URL') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Setup') {
                        setOpenActions(-1);
                        openModelSetupModal();
                      }
                      if (action.title === 'View') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Predict') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Download') {
                        const fileName = row.file_name;
                        const downloadFileUrl = `/blob_file/download?container_name=containerfromblobservice${userId}&file_name=${fileName}`;

                        try {
                          setIsDownloaded(true);
                          // await dispatch(downloadMiddleware.downloadFile(downloadFileUrl));
                          const a = await API.download.downloadFile(downloadFileUrl);
                          const fileContents = downloadSelector.downloadData(store.getState());
                          if (a.status === 200) {
                            setIsDownloaded(false);
                            toast.success(`Downloaded ${fileName}:`);
                          } else {
                            toast.error(removeLastExtension(a.data.message));
                          }

                          const blob = new Blob([a.data], { type: 'application/octet-stream' });
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement('a');
                          link.href = url;
                          link.download = fileName;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        } catch (error: any) {
                          setIsDownloaded(false);
                          toast.error(removeLastExtension(error?.response.data.message));
                          console.error(`An error occurred while downloading the file ${fileName}:`, error);
                        }
                      }

                      if (action.title === 'Deploy') {
                        if (row.model_type === 'uploaded_model') {
                          await pendingAction(false, action, index);
                        } else {
                          setShowConfirmDeployTooltip(true);
                        }
                        setAction(action);
                        setIndex(index);
                      }

                      if (action.title === 'Undeploy') {
                        setIsLoading('Undeploy');
                        const fileName = row.file_name;
                        const unDeployUrl = `/model_deployment/undeploy_model?model_id=${fileName}&container_name=containerfromblobservice${userId}`;
                        const unDeployData = await dispatch(undeployMiddleware.getUndeploy(unDeployUrl));

                        if (unDeployData && unDeployData.status === 200) {
                          setIsLoading('');
                          if (setActions) {
                            const newActions = [...modelActions1];
                            setActions(newActions);
                            setIsLoading('');
                          }
                          dispatch(userModelsMiddleware.getUserModels(getUserModelsURL));
                          toast.success('Undeploy successful!');
                          setOpenActions(-1);
                        } else {
                          setIsLoading('');
                          toast.error(removeLastExtension(unDeployData?.data.message));
                          setOpenActions(-1);
                        }
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title === 'Download' && isDownloaded ? (
                      <CircularProgress style={{ height: '24px', color: 'blue' }} />
                    ) : action.title === 'Download' && !isDownloaded ? (
                      action.title
                    ) : (
                      action.title
                    )}
                  </Button>
                )}
              </>
            )
        )}
      {!isModels &&
        actions.map(
          (action, index) =>
            action.state && (
              <Grid item key={action.title}>
                {isLoading === action.id ? (
                  <Box sx={{ p: 1 }}>
                    <CircularProgress style={{ color: 'blue' }} />
                  </Box>
                ) : (
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    onClick={async (e) => {
                      if (action.title === 'Delete' || action.title === 'Summary') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Preview') {
                        setOpenActions(-1);
                        if (action.openFunction) {
                          action.openFunction(
                            { fileName: row.file_name ?? row.file_name },
                            { fileSize: row.size ?? row.file_name },
                            { id: row.model_id ?? '' },
                            { setChecked },
                            { shapValueWanted },
                            { isModels: isModels ? isModels : false },
                            { selected },
                            { setSelected }
                          );
                        }
                      }
                      if (action.title === 'Download') {
                        const fileName = row.file_name;
                        const downloadFileUrl = `/blob_file/download?container_name=containerfromblobservice${userId}&file_name=${fileName}`;

                        try {
                          setIsDownloaded(true);
                          // await dispatch(downloadMiddleware.downloadFile(downloadFileUrl));
                          const a = await API.download.downloadFile(downloadFileUrl);
                          const fileContents = downloadSelector.downloadData(store.getState());
                          if (a.status === 200) {
                            setIsDownloaded(false);
                            toast.success(`Downloaded ${fileName}:`);
                          } else {
                            toast.error(removeLastExtension(a.data.message));
                          }

                          const blob = new Blob([a.data], { type: 'application/octet-stream' });
                          const url = window.URL.createObjectURL(blob);
                          const link = document.createElement('a');
                          link.href = url;
                          link.download = fileName;
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        } catch (error) {
                          setIsDownloaded(false);
                          toast.error(`Could not download ${fileName}:`);
                          console.error(`An error occurred while downloading the file ${fileName}:`, error);
                        }
                      }
                    }}
                    sx={action.style}
                  >
                    {action.title === 'Download' && isDownloaded ? (
                      <CircularProgress style={{ height: '25px', width: '25px', color: 'blue' }} />
                    ) : action.title === 'Download' && !isDownloaded ? (
                      action.title
                    ) : (
                      action.title
                    )}
                  </Button>
                )}
              </Grid>
            )
        )}
      <IconButton
        size="large"
        onClick={() => {
          setOpenActions(-1);
          setShowConfirmDeployTooltip(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      {showConfirmDeployTooltip && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '-56px', // Adjust the top position as needed
            right: '-5%',
            transform: 'translateX(-50%)',
            backgroundColor: 'lightblue',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)'
          }}
          className="confirmationTooltip"
        >
          <p style={{ margin: '2px' }}>Create service for Shap values?</p>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button onClick={handleAccept}>Yes</button>
            <button onClick={handleDecline}>No</button>
          </div>
          <div
            style={{
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '0',
              height: '0',
              borderTop: '10px solid lightblue',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent'
            }}
          />
        </div>
      )}
      {isModelSetupModalOpen && (
        <ModelSetupModalComponent
          setOpen={setOpen}
          modelName={row.file_name}
          onClose={closeModelSetupModal}
          title="Model Setup"
        />
      )}
    </div>
  );
};

export default DashboardActions;
