import { dispatch, useAppSelector } from '../../store/hooks';
import { summaryMiddleware, summarySelector } from '../../store/slices/summary';
import React, { useEffect, useState } from 'react';
import { modalsMiddleware } from '../../store/slices/modals';
import { ModalName } from '../../types/modals';
import { datasourceSummaryIcons } from '../../helpers/dashboardData';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { datasourceColumnsMiddleware } from '../../store/slices/datasourceColumns';
import { datasourceUsersMiddleware, datasourceUsersSelector } from '../../store/slices/datasourceUsers';
import { modelTrainMiddleware } from '../../store/slices/modelTrain';
import { trainStatusMiddleware } from '../../store/slices/trainStatus';
import { trainProgressMiddleware } from '../../store/slices/trainProgress';
import { toast } from 'react-toastify';
import { userModelsMiddleware } from '../../store/slices/userModels';
import { predictionMiddleware, predictionSelector } from '../../store/slices/prediction';
import { predictionStatusMiddleware } from '../../store/slices/predictionStatus';
import { predictionProgressMiddleware } from '../../store/slices/predictionProgress';
import { resourcesMiddleware } from '../../store/slices/resources';
import Progress from '../Progress';
import { MbConverter } from '../../helpers/MbConverter';
import { removeLastExtension } from '../../helpers/removeExtension';

export interface ModelManagerInferenceDialogProps {
  fileName: string;
  fileSize: number;
  predictionTraining: boolean;
  predictionProgress: number;
  setPredictionProgress: (value: any) => void;
  setPredictionTraining: (value: any) => void;
}

const ModelManagerInferenceDialog = ({
  fileName,
  fileSize,
  predictionTraining,
  predictionProgress,
  setPredictionProgress,
  setPredictionTraining
}: ModelManagerInferenceDialogProps) => {
  const [dataset, setDataset] = useState<string>('');
  const [training, setTraining] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const { datasourceUsersData } = useAppSelector(datasourceUsersSelector.DatasourceUsersData);
  const { predictionData } = useAppSelector(predictionSelector.predictionData);
  const { predictionStatus } = useAppSelector(predictionSelector.predictionData);
  const getDatasourceUsersURL = `/datasource/user_datasources?container_name=containerfromblobservice${localStorage.getItem(
    'userId'
  )}`;

  useEffect(() => {
    dispatch(datasourceUsersMiddleware.getDatasourceUsers(getDatasourceUsersURL));
  }, []);

  const datasetOptionsWithIds = datasourceUsersData.map((option: any, index: any) => ({
    id: option,
    label: removeLastExtension(option)
  }));

  const onModalClose = () => {
    dispatch(modalsMiddleware.closeModal(ModalName.ModelManagerInferenceDialog));
  };

  const datasetValue = (selectedOption: any) => {
    const value = selectedOption.id;
    if (value === undefined) {
      setDataset('');
    } else {
      setDataset(value);
    }
  };

  const inferenceClick = async () => {
    const userId = localStorage.getItem('userId');
    await setTraining(true);
    const predictionURL = `/inference/get_predictions?container_name=containerfromblobservice${userId}&model_name=${fileName}&data_source_name=${dataset}`;
    const prediction = await dispatch(predictionMiddleware.getPredictionData(predictionURL));

    if (prediction && prediction.status === 200) {
      const getPredictionStatusURL = `/inference/inference_status/${prediction.data.status_update}`;
      const getPredictionProgressURL = `/inference/inference_progress/${prediction.data.status_update}`;
      let intervalId: any = setInterval(async () => {
        const dataOfPredictionStatus = await dispatch(
          predictionStatusMiddleware.getPredictionStatusData(getPredictionStatusURL)
        );
        const dataOfPredictionProgress = await dispatch(
          predictionProgressMiddleware.getPredictionProgressData(getPredictionProgressURL)
        );
        if (
          dataOfPredictionProgress &&
          dataOfPredictionProgress.data.progress &&
          dataOfPredictionProgress.data.progress.length > 0
        ) {
          setProgress(dataOfPredictionProgress.response.data.progress);
        }
        if (dataOfPredictionStatus && dataOfPredictionStatus.data.queue_state === 'SUCCESS') {
          toast.success('The new file with predictions created in datasets section.');
          setTraining(false);
          // const getAllResourcesURL = `/data/all-files?container_name=containerfromblobservice${localStorage.getItem(
          //   'userId'
          // )}`;
          // dispatch(resourcesMiddleware.getResources(getAllResourcesURL));
          onModalClose();
          clearInterval(intervalId);
          setProgress(0);
        }
        if (
          dataOfPredictionStatus === undefined ||
          dataOfPredictionProgress === undefined ||
          dataOfPredictionStatus.data.queue_state === 'FAILURE'
        ) {
          onModalClose();
          clearInterval(intervalId);
          setTraining(false);
          toast.error(dataOfPredictionStatus && removeLastExtension(dataOfPredictionStatus.data.message));
          setProgress(0);
        }
        if (dataOfPredictionStatus && Object.keys(dataOfPredictionStatus).length === 0) {
          clearInterval(intervalId);
          setTraining(false);
          toast.error(removeLastExtension(dataOfPredictionStatus.data.message));
        }
      }, 5000);
    } else {
      onModalClose();
    }
  };

  return (
    <Dialog fullWidth maxWidth="lg" onClose={onModalClose} aria-labelledby="simple-dialog-title" open>
      <Box>
        <DialogTitle style={{ textAlign: 'center', position: 'relative' }} id="draggable-dialog-title">
          Model Inference
          <IconButton
            onClick={onModalClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 10
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {training ? (
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title={`${removeLastExtension(fileName)}`} />
            <CardContent>
              <div style={{ padding: '70px 230px' }}>
                <Progress progress={progress} setPredictionProgress={setProgress} />
              </div>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title={`${removeLastExtension(fileName)}`} />
            <CardContent sx={{ pt: 0 }}>
              <p style={{ fontSize: '18px', fontWeight: '600' }}>Select Dataset</p>
              <Autocomplete
                style={{ width: '50%' }}
                options={datasetOptionsWithIds}
                onChange={(event, newValue) => datasetValue(newValue)}
                renderInput={(params) => <TextField {...params} placeholder="File name" label="" />}
              />
              <Button
                variant="contained"
                size="large"
                style={{
                  float: 'right',
                  backgroundColor: dataset === '' ? '#CCCCCC' : '#202090',
                  color: dataset === '' ? '#666666' : '#fff'
                }}
                sx={{ margin: '20px auto', height: '52px' }}
                onClick={inferenceClick}
                disabled={dataset === ''}
              >
                Predict
              </Button>
            </CardContent>
          </Card>
        )}
      </Box>
    </Dialog>
  );
};

export default ModelManagerInferenceDialog;
